// import { Button } from "@mui/material"
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function App() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs>
        <img 
            src="/image/IMG_4065.JPG" 
            alt="My Image" 
            style={{ 
              maxWidth: '50%', 
              height: 'auto',
              borderRadius: '50%', // Make the image appear as a circle
              display: 'block', // Ensures that the border-radius applies correctly
              border: '2px solid black'
            }} 
          />
        </Grid>
        <Grid item xs={6}>
          <Item>XINYU XIE</Item>
        </Grid>
        <Grid item xs>
          <Item>xs</Item>
        </Grid>
      </Grid>
    </Box>
  );
}


// const App = () => {
//   return (
//     <div>
//       <div>
//         App
//       </div>
//       <div>
//         App
//       </div>
//       <Button variant="contained">Contained</Button>
//       <img src="/image/IMG_4065.JPG"></img>
//     </div>
//   )
// }

// export default App